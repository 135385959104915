import axios from 'axios';

const sendContactForm = async (form: { name: string; email: string }) => {
    const data = {
        to: 'c-level@cappacho.com',
        subject: '¡Nuevo lead en nuestra lista de espera!',
        text: `¡Hey, hey C-Level!\nUna nueva perosna se ha unido a la lista de espera.\n\nNombre: ${form.name}\nCorreo: ${form.email}\n`,
    };
    try {
        const response = await axios.post(
            'https://users.dev.cappacho.com/api/v1/contact-form',
            data
        );
        return response.data;
    } catch (err) {
        console.error('[!] Error sending contact form:', err);
        return { success: false };
    }
};

export { sendContactForm };
